<template>
    <UserLayout :smallerTopMargin="true" mainPadding="pb-30">
        <Link
            v-if="final_cut_off_date"
            :href="route('subscriptions.final-cut-off')"
            class="flex flex-col justify-center items-center text-center text-white bg-red-600 p-4 cursor-pointer shadow-lg"
        >
            <!--            <h1 class="text-xl font-bold">Last chance to secure, final order cut off: {{ getDateFromISO(final_cut_off_date).toLocaleString(DateTime.DATE_MED) }} > </h1>-->
            <h1 class="text-xl font-bold">
                <i class="fa-regular fa-clock"></i> This weeks Final Order Cutoff:
                {{ getDateFromISO(final_cut_off_date).toLocaleString(DateTime.DATE_MED) }}
                <i class="fa-solid fa-chevron-right"></i>
            </h1>
        </Link>
        <div class="p-6 w-full">
            <Link
                v-if="homepage_config?.primaryContentImage"
                :href="linkHref(homepage_config?.primaryContentLinkType, homepage_config?.primaryContentLinkId)"
            >
                <img :src="homepage_config?.primaryContentImage" alt="Main Image" class="block w-full" />
            </Link>
        </div>

        <div class="flex flex-col xxs:flex-row justify-between content-stretch gap-6 p-6 pt-0">
            <Link
                class="w-full"
                v-if="homepage_config?.middleContentLeftImage"
                :href="linkHref(homepage_config?.middleContentLeftLinkType, homepage_config?.middleContentLeftLinkId)"
            >
                <img :src="homepage_config?.middleContentLeftImage" alt="1st Image" class="block w-full" />
            </Link>
            <Link
                class="w-full"
                v-if="homepage_config?.middleContentRightImage"
                :href="linkHref(homepage_config?.middleContentRightLinkType, homepage_config?.middleContentRightLinkId)"
            >
                <img :src="homepage_config?.middleContentRightImage" alt="2nd Image" class="block w-full" />
            </Link>
        </div>

        <div class="bg-secondary-800 p-8">
            <div class="text-center pb-6">
                <h2 class="text-2xl text-white font-[1000] uppercase">Most Subscribed</h2>
            </div>
            <div class="flex justify-around gap-6 overflow-auto">
                <Link
                    :href="'/subscriptions/item/' + item.id"
                    class="flex flex-col justify-between gap-4 flex-1 most-subscribed-only-media"
                    v-for="item in topFiveSubscribed"
                >
                    <ComicImage :image="getNextItem(item).path" :is_foil="getNextItem(item).is_foil" />
                    <h3 class="text-white text-lg uppercase text-center">{{ item.name }}</h3>
                </Link>
            </div>
        </div>
    </UserLayout>
</template>

<script setup>
    import UserLayout from "../../Layouts/SubscriptionsLayouts/UserLayout.vue";
    import { Link } from "@inertiajs/vue3";
    import ComicImage from "@/Components/ComicImage.vue";
    import { getDateFromISO } from "@/Helpers/DateHelper.js";
    import { DateTime } from "luxon";

    const props = defineProps({
        canLogin: Boolean,
        canRegister: Boolean,
        homepage_config: Object,
        topFiveSubscribed: Object,
        final_cut_off_date: String,
    });

    const linkHref = (type, id) => {
        if (id && type) {
            if (type === "category") {
                return `/category/${id}`;
            }
            if (type === "subscription") {
                return `/subscriptions/item/${id}`;
            }
        }

        return "";
    };

    const getNextItem = (item) => {
        if (item?.next_released_item) {
            return {
                path: item?.next_released_item?.cover_image_path,
                is_foil: item?.next_released_item?.is_foil,
            };
        }

        return {
            path: item?.default_cover_image_path || "/IMAGE-NOT-YET-AVAILABLE.png",
            is_foil: false,
        };
    };
</script>

<style scoped>
    @media only screen and (max-width: 449px) {
        .most-subscribed-only-media:nth-last-child(-n + 3) {
            display: none;
        }
    }

    @media only screen and (min-width: 450px) and (max-width: 749px) {
        .most-subscribed-only-media:nth-last-child(-n + 2) {
            display: none;
        }
    }

    @media only screen and (min-width: 750px) and (max-width: 1023px) {
        .most-subscribed-only-media:nth-last-child(-n + 1) {
            display: none;
        }
    }

    @media only screen and (min-width: 1024px) {
        .most-subscribed-only-media {
            display: flex;
        }
    }
</style>
